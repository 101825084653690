// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCFFjsBk5Xh490DYaNWafouWG9wdkatpAE",
    authDomain: "wear-optimization.firebaseapp.com",
    databaseURL: "https://wear-optimization.firebaseio.com",
    projectId: "wear-optimization",
    storageBucket: "wear-optimization.appspot.com",
    messagingSenderId: "210681633681",
    appId: "1:210681633681:web:ea934b33ffe556de095830",
    measurementId: "G-TD1V63HWH4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
