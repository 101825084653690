import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Storage } from '@ionic/storage';
@Component({
  selector: 'app-use-terms-modal',
  templateUrl: './use-terms-modal.page.html',
  styleUrls: ['./use-terms-modal.page.scss'],
})
export class UseTermsModalPage implements OnInit {
  toggle: boolean;
  accepted: boolean;
  constructor(private modalController: ModalController,
    private analyticsService: AnalyticsService,
    private storage: Storage) {

  }

  ngOnInit() {
    this.toggle = this.analyticsService.getToggle();
    console.log("toggle: ", this.toggle)
    this.storage.get("UseTerms").then(value => {
      this.accepted = value
    })
  }

  close() {
    this.modalController.dismiss();
    this.storage.set("UseTerms", true);

    console.log("toggle: ", this.toggle)
    this.analyticsService.toggleAnalytics(this.toggle);
  }

}
