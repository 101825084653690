import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.page.html',
  styleUrls: ['./language-modal.page.scss'],
})
export class LanguageModalPage implements OnInit {
  langs: any = null;
  selected: any;
  constructor(private languageService: LanguageService,
    private modalControllder: ModalController,
    private storage: Storage,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.getLanguages();

    // this.storage.get('LNG_KEY').then(value => {
    //   this.selected = value;
    //   console.log("in modal", value)
    // });
    // console.log('current usedclang:', this.languageService.selected)
    this.storage.get('LNG_KEY').then(val => this.selected = val);
    console.log("selected from storage in modal: ", this.selected)

  }

  getLanguages() {
    this.langs = this.languageService.getLanguages()
  }
  selectLanguage(l) {
    console.log(l)

    this.languageService.setLanguage(l);
    this.close()

  }
  close() {
    this.modalControllder.dismiss()
  }
}
