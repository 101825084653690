import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
  private isDesktop = new BehaviorSubject<boolean>(false);
  constructor() { }

  onResize(size){
    this.isDesktop.next(size > 568);
  }

  isDesktopView(){
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
}
