import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavParams, ModalController, IonSlides } from '@ionic/angular';
import {getPlatforms} from '@ionic/angular';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.page.html',
  styleUrls: ['./image-modal.page.scss'],
})
export class ImageModalPage implements OnInit {
  detailurl: any;
  weartypeurl: any;
  device: any;
  @ViewChild('slider', {read: ElementRef})slider: ElementRef;
  sliderOpts = {
    loop:true,
    zoom:{
      maxRatio: 2
    }
  }
  zoomToggle:boolean = true;
 
  constructor(private navParams: NavParams, private modalController: ModalController) { 
    this.checkDevice();
    console.log('this device:', this.device);
  }

  ngOnInit() {
    this.detailurl = this.navParams.get('detailurl');
    this.weartypeurl = this.navParams.get('weartypeurl');
  
  }
  ionViewDidEnter(){
    this.slider.nativeElement.update();
  }
  
  next(){
    this.slider.nativeElement.slideNext();
  }
  previous(){
    this.slider.nativeElement.slidePrev();
  }
  zoom(){
    let zoom = this.slider.nativeElement.swiper.zoom;
    if (this.zoomToggle){
      zoom.in()
    }else{
      zoom.out()
    }
    this.zoomToggle = !this.zoomToggle;
  }


  close(){
    this.modalController.dismiss();
  }

  checkDevice(){
    let deviceInfos = getPlatforms();
    if(deviceInfos.includes('mobile')){
      this.device = "mobile";
    }else{
      this.device = "desktop";
    }
  }
}
