import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Globalization } from '@ionic-native/globalization/ngx'
import { ModalController, Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { getPlatforms } from '@ionic/angular';
import { LanguageModalPage } from '../pages/language-modal/language-modal.page';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AnalyticsService } from './analytics.service';
const { Device } = Plugins;
const LNG_KEY = "SELECTED_LANGUAGE";
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = '';
  initial_language: any = "";
  constructor(private translate: TranslateService, private storage: Storage, private globalization: Globalization, private platform: Platform,
    private modalController: ModalController, private analytics: AnalyticsService) { }

  setInitalLanguage() {
    // console.log(" Default Browser Language: ", this.translate.getBrowserLang())

    this.storage.get("LNG_KEY").then(value => {
      if (value == null) {
        console.log("Language empty", value);
        Device.getLanguageCode().then(language => {
          this.setLanguage(language.value.slice(0, 2))
          console.log("I come from Device", language.value.slice(0, 2))
        });
      } else {
        // USE STORED LANGUAGE
        this.setLanguage(value)
      }
    })


    // console.log("in inital: ", this.selected)
  }
  getLanguages() {
    return [
      { text: 'Castellno', value: 'es' },
      { text: 'Česky', value: 'cs' },
      { text: 'Danske', value: 'da' },
      { text: 'Deutsch', value: 'de' },
      { text: 'Deutsch-Schwäbisch', value: 'swg' },
      { text: 'English (UK)', value: 'en_GB' },
      { text: 'English (US)', value: 'en' },
      { text: 'Français', value: 'fr' },
      { text: 'Italiano', value: 'it' },
      { text: 'Magyar', value: 'hu' },
      { text: 'Nederlands', value: 'nl' },
      { text: 'Polska', value: 'pl' },
      { text: 'Português', value: 'pt' },
      { text: 'Română', value: 'ro' },
      { text: 'Slovenski jezik', value: 'sl' },
      { text: 'Slovenský jazyk', value: 'sk' },
      { text: 'Svenska', value: 'sv' },
      { text: 'Türkçe', value: 'tr' },
      { text: 'Русский', value: 'ru' },
      { text: '中文 (Chinese)', value: 'zh' },
      { text: '繁体中文 (Chinese Traditional)', value: 'zh_TW' },
      { text: '日本語 (Japanese)', value: 'ja' },
      { text: '한국어 (Korean)', value: 'ko' },
      { text: 'ภาษาไทย (Thai)', value: 'th' }
    ]
  }
  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    this.storage.set('LNG_KEY', lng);
    console.log("setLanguage called: " + lng);
    //window.localStorage.setItem('LNG_KEY', lng);
    this.analytics.logEvent("Language", lng);
  }

  getSelected() {
    return this.selected;
  }


}
