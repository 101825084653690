import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';




const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },




  {
    path: 'use-terms-modal',
    loadChildren: () => import('./pages/use-terms-modal/use-terms-modal.module').then(m => m.UseTermsModalPageModule)
  },
  {
    path: 'language-modal',
    loadChildren: () => import('./pages/language-modal/language-modal.module').then(m => m.LanguageModalPageModule)
  },
  {
    path: 'operation',
    loadChildren: () => import('./pages/operation/operation.module').then(m => m.OperationPageModule)
  },
  {
    path: 'weartypes',
    loadChildren: () => import('./pages/weartypes/weartypes.module').then(m => m.WeartypesPageModule)
  },
  {
    path: 'weardetails',
    loadChildren: () => import('./pages/weardetails/weardetails.module').then(m => m.WeardetailsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
