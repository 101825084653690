import { Component, HostListener } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { getPlatforms, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LanguageService } from 'src/app/services/language.service'
import { ScreenSizeService } from './services/screen-size.service';
import { Capacitor } from '@capacitor/core';
import { Plugins } from '@capacitor/core';

import { LanguageModalPage } from './pages/language-modal/language-modal.page';
import { UseTermsModalPage } from './pages/use-terms-modal/use-terms-modal.page';
import { TranslateService } from '@ngx-translate/core';
import { observable } from 'rxjs';
import { browser } from 'protractor';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [GoogleAnalytics]
})
export class AppComponent {
  useTermsTitle: any;
  language: any;
  constructor(
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private languageService: LanguageService,
    private screenSizeService: ScreenSizeService,
    private ga: GoogleAnalytics,
    private platform: Platform,
    private modalController: ModalController,
    private translate: TranslateService) {
    this.initializeApp();

  }

  initializeApp() {
    //this.languageService.setInitalLanguage();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString("#ffffff");
      //this.statusBar.styleLightContent();
      //this.splashScreen.hide();

      this.languageService.setInitalLanguage();
      this.language = this.languageService.selected;
    });
    this.screenSizeService.isDesktopView().subscribe(isDesktop => {
      console.log("changed:", isDesktop);
    })

  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    //console.log('resize:', event);
    this.screenSizeService.onResize(event.target.innerWidth);
  }



  openLangs() {
    console.log('open langs called')
    this.modalController.create({
      component: LanguageModalPage,
      cssClass: 'settings-modal'
    }).then(modal => modal.present())
  }
  openUseTerms() {
    this.modalController.create({
      component: UseTermsModalPage,
      cssClass: 'useTerms-modal'
    }).then(modal => modal.present())
  }

  giveFeedback() {
    var language = this.languageService.selected
    var platform = getPlatforms()

    var string = "mailto:e-marketing@walter-tools.com?subject=Feedback from Walter Wear Optimization&body=Please do not remove the following: Version 2.0, language:" + language + ", Platform: " + platform
    window.open(string)
  }
}
