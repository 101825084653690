import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
const { FirebaseAnalytics, Device } = Plugins;
import { Storage } from '@ionic/storage';
import "@capacitor-community/firebase-analytics";
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled: boolean;
  constructor(private storage: Storage) {
    this.initFireAnalytics();
    this.storage.get("analytics").then(val => {
      this.analyticsEnabled = val
      console.log("enabled: ", this.analyticsEnabled)
    })
  }

  async initFireAnalytics() {
    // if ((await Device.getInfo()).platform == "web") {
    //   FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    // }
    // FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
  }

  logEvent(key, value) {
    FirebaseAnalytics.logEvent({
      name: key,
      params: {
        value: value
      }
    })

    console.log("event logged: ", key, value)
  }
  logTestEvent() {
    Device.getInfo().then(val => console.log(val));
    console.log("Analytics: Test Event called")
    FirebaseAnalytics.logEvent({
      name: "test",
      params: {
        test: "test"
      }
    })
  }
  setUser() {
    console.log("set user called")
    FirebaseAnalytics.setUserProperty({
      name: "mike",
      value: "test"
    })
  }

  toggleAnalytics(bool: boolean) {
    this.analyticsEnabled = bool
    FirebaseAnalytics.setCollectionEnabled(
      {
        enabled: this.analyticsEnabled,
      }
    )
    this.storage.set("analytics", bool)
  }
  getToggle() {
    return this.analyticsEnabled
  }
}
